<template>
  <div class="container" fluid="lg">
    <user-header></user-header>

    <div class="row">
      <div
        v-for="notification in notifications"
        :key="notification.id"
        class="col-12 py-3"
        :class="notification.read_at ? '' : 'bg-gray'"
      >
        <div class="d-flex justify-content-between align-items-center">
          <h6>{{ notification.title }}</h6>
          <div class="small text-light">
            {{ notification.created_at | DateFormatter }}
          </div>
        </div>
        <div class="small">{{ notification.content }}</div>
      </div>
    </div>

    <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler">
      <div slot="spinner" class="py-4">
        <b-spinner variant="primary" />
      </div>
      <div slot="no-more" class="py-4">{{ $t('no_more_notification') }}</div>
      <div slot="no-results" class="py-4">{{ $t('no_results') }}</div>
    </infinite-loading>
  </div>
</template>

<script>
import UserHeader from '@/components/UserHeader.vue'
import InfiniteLoading from 'vue-infinite-loading'
import NotificationService from '@/api/service/NotificationService'

import { BSpinner } from 'bootstrap-vue'
export default {
  components: { UserHeader, InfiniteLoading, BSpinner },
  data() {
    return {
      notifications: [],
      currentPage: 1,
      hasNextPage: false,
      infiniteId: 1,
    }
  },
  watch: {
    '$route.params.type': {
      handler: function (type) {
        this.notificationType = type
        this.refetchData()
        this.getNotificationCount()
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.notificationType = this.$route.params.type
    this.getNotificationCount()
  },
  methods: {
    async infiniteHandler($state) {
      try {
        const res = await NotificationService.getNotificationList({
          page: this.currentPage,
        })
        const response = res.data.data
        const ids = response.map((res) => res.id)
        this.readNotification(ids)
        if (response.length) {
          response.forEach((res) => {
            const data = JSON.parse(res.data)
            this.notifications.push({
              id: res.id,
              title:
                this.$i18n.locale == 'mm' ? data.mm_title : this.$i18n.locale == 'en' ? data.en_title : data.th_title,
              content:
                this.$i18n.locale == 'mm'
                  ? data.mm_content
                  : this.$i18n.locale == 'en'
                  ? data.en_content
                  : data.th_content,
              created_at: res.created_at,
            })
          })
          this.currentPage += 1
          $state.loaded()
        } else {
          $state.complete()
        }
      } catch (err) {
        $state.complete()
        this.showAlert(err)
      }
    },
    refetchData() {
      this.notifications = []
      this.currentPage = 1
      this.infiniteId += 1
    },
    async readNotification(ids) {
      try {
        await NotificationService.readNotification({ ids: ids })
      } catch (err) {
        console.log(err)
      }
    },
    async getNotificationCount() {
      try {
        const { data } = await NotificationService.notificationCount()
        this.$store.dispatch('notification/setCount', {
          count: data.data,
        })
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>

<style></style>
