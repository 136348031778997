import client from '@/api/AxiosInstance.js'
export default {
  storeUserId(params) {
    return client.post('/store/onesignal_user_id', {
      one_signal_user_id: params.one_signal_user_id,
    })
  },

  getNotificationList(params) {
    return client.get(`/notification?page=${params.page}`)
  },

  notificationCount() {
    return client.get('/count/notification')
  },

  readNotification(params) {
    return client.post('/notification/read', {
      ids: params.ids,
    })
  },
}
